<template>
<div class="row tgju-widgets-row dashboard-widget-profile widget-border-fix widget-padding" style="padding:0px !important;">
    <div class="col-12 col-md-12 col-xl-6">
        <div class="row tgju-widgets-row mr-1 mb-3">
            <div :class="'widget-select w-100w '+ discussion_active.text +''">
                <v-select2 v-model="discussion_active" @input="componentKey += 1" :clearable="false" label="value" :options="discussions">
                    <i class="uil uil-list-ui-alt"></i>
                </v-select2>
            </div>
            <TgjuPost message="کاربر مورد نظر در این کانال تاکنون دیدگاهی به ثبت نرسانده است" type="widget" auth="hide" :username="this.$route.params.username" sendbox="disabled" :subject="discussion_active.text" col="w100-w" :key="componentKey"></TgjuPost>
        </div>
    </div>

    <div class="tgju-widgets-block col-12 col-md-12 col-xl-6 p-0 profile-blocks">
        <div class="row tgju-widgets-row">

            <div class="tgju-widgets-block col-12 col-md-12 col-xl-12 col-xxxl-6">
                <div class="row tgju-widgets-row">
                    <div class="tgju-widgets-block col-12" data-widget-type="news" data-widget-id="4" data-widget-size="col-xl-4" data-widget-options='{"id":"1","count":"8"}'>
                        <LastPosts :username="this.$route.params.username"></LastPosts>
                    </div>
                </div>
            </div>

            <div class="tgju-widgets-block col-12 col-md-12 col-xl-12 col-xxxl-6">
                <div class="row tgju-widgets-row">
                    <div class="tgju-widgets-block col-12" data-widget-type="news" data-widget-id="4" data-widget-size="col-xl-4" data-widget-options='{"id":"1","count":"8"}'>
                        <LastFollowingTechnicals :username="this.$route.params.username"></LastFollowingTechnicals>
                    </div>
                </div>
            </div>

        </div>
    </div>

</div>
</template>

<script>
// این ویو برای نمایش پروفایل کاربران دیگر است
import TgjuPost from '@/components/TgjuPosts/Post.vue'
import LastPosts from '@/components/LastPosts.vue'
import LastFollowingTechnicals from '@/components/LastFollowingTechnicals.vue'
import vSelect2 from 'vue-select'
Vue.component('v-select2', vSelect2);

export default {
    name: 'ProfileOthersView',
    components: {
        TgjuPost,
        LastPosts,
        LastFollowingTechnicals,
    },
    data: function () {
        return {
            componentKey: 0,
            discussion_active: { text: 'tgju-discussion-18', value: 'بازار طلا' },
            discussions: [
                { text: 'tgju-discussion-18', value: 'بازار طلا' },
                { text: 'tgju-discussion-19', value: 'بازار سکه' },
                { text: 'tgju-discussion-20', value: 'بازار ارز' },
                { text: 'tgju-discussion-global-2', value: 'بازار ارزهای دیجیتال' },
                { text: 'tgju-discussion-28', value: 'بازار بورس' },
                { text: 'tgju-discussion-36', value: 'بازار‌های کالایی' },
                { text: 'tgju-discussion-global-1', value: 'بازار‌های سهام' },
                { text: 'tgju-discussion-37', value: 'بازار نفت و انرژی' },
                { text: 'tgju-discussion-global-3', value: 'بازار مبادلات ارزی' },
                { text: 'tgju-discussion-35', value: 'بازار فلزات' },
            ],
        }
    },
    // متد کرییت قبل از متد مانت در ویو اجرا میشود
    created() {
        // در صورتی که منوی داشبورد سمت راست به دلایلی استایل گرفته بود نمایش داده نشودم
        // این استایل را از بین ببر و اجازه بده به نمایش در بیاید
        var item = document.getElementsByClassName('bar-menu-dashboard-box');
        item[0].style.display = "";

        // در صورتی که کاربر لاگین کرده بود
        if (this.$helpers.userSync()) {
            // یک سری اکشن ها رو کال کن
            this.$store.commit('setAccessToken', localStorage.getItem('access_token'));
            this.$store.dispatch('setUserData', { forced: true })
                .then(response => {
                    // ریدارکت کردن کاربر به پروفایل خود درصورت اینکه نام کاربری خود را درخواست کرده باشد در روت
                    if (this.$helpers.userAuth()) {
                        var username = this.$store.state.user ? this.$store.state.user.username : this.$helpers.json_pars(localStorage.getItem('user_data')).username;

                        if (username == this.$route.params.username) {
                            window.location = process.env.VUE_APP_URL + '/profile';
                        }
                    }
                });
            this.$store.dispatch('setDashboards', { forced: true });
        }
    },
    methods: {
        // این متد برای هندل کردن اکشن تغییر دسته بندی نظرات استفاده میشود
        onChange(event) {
            this.discussion = event.target.value;
            this.componentKey += 1
        },
    },
}
</script>
